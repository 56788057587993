<template>
  <div class="animated fadeIn">
    <b-card class="min-vh-100" v-if="user">
      <b-row v-if="user.contributor_status === 'inactive'">
        <div class="display-table full-width mb-2">
          <strong class="card-title ml-3">Dashboard</strong>
        </div>
        <b-col md="6" v-for="stats, index in masters.stats" :key="index">
          <stats-card 
            :title="stats.title"
            :sub-title="stats.subtitle"
            :icon="stats.icon"
            :class="stats.className">
          </stats-card>
        </b-col>
      </b-row>
      <b-row v-else>
        <div class="display-table full-width mb-2">
          <strong class="card-title ml-3">My Dashboard</strong>
        </div>
        <b-col md="6" v-for="stats, index in masters.contributors" :key="index">
          <stats-card 
            :title="stats.title"
            :sub-title="stats.subtitle"
            :icon="stats.icon"
            :class="stats.className">
          </stats-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import api from '@/utils/api'
import StatsCard from '@/components/Cards/StatsCard';
import { mapState } from 'vuex';

export default {
  name: 'dashboard',
  components: {
    StatsCard
  },
  data: function () {
    return {
      masters: {
        stats: [
          {
            title: 'Total News',
            subtitle: '0',
            icon: 'fa fa-newspaper-o fa-2x',
            className: 'text-primary'
          },
          {
            title: 'Total Membership',
            subtitle: '0',
            icon: 'fa fa-heart fa-2x',
            className: 'text-danger'
          },
          {
            title: 'Total Visitor Today',
            subtitle: '0',
            icon: 'fa fa-users fa-2x',
            className: 'text-success'
          },
          {
            title: 'Total Contributor',
            subtitle: '0',
            icon: 'fa fa-user fa-2x',
            className: 'text-warning'
          },
        ],
        contributors: [
          {
            title: 'Total News',
            subtitle: '0',
            icon: 'fa fa-newspaper-o fa-2x',
            className: 'text-primary'
          },
          {
            title: 'Total News Approved',
            subtitle: '0',
            icon: 'fa fa-check fa-2x',
            className: 'text-success'
          },

        ]
      }
    }
  },
  computed: {
    ...mapState(['user'])
  },
  mounted() {
    this.$axios.get(api.dashboard)
      .then(res => {
        const {data} = res.data
        if(this.user.contributor_status === 'inactive') {
          this.masters.stats[0].subtitle = `${data.total_news}`
          this.masters.stats[1].subtitle = `${data.total_membership}`
          this.masters.stats[2].subtitle = `${data.total_visitor}`
          this.masters.stats[3].subtitle = `${data.total_contributor}`
        } else {
          this.masters.contributors[0].subtitle = `${data.total_news}`
          this.masters.contributors[1].subtitle = `${data.total_news_approve}`
        }
      })
  }
}
</script>
