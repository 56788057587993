<template>
  <card class="card-stats shadow" :show-footer-line="true">
    <b-row class="align-items-center py-2">

      <b-col class="text-center">
        <i :class="icon" class="my-2 mb-3"></i>
        <div>
          <span class="h2 font-weight-bold" v-if="subTitle">{{subTitle}}</span>
          <h5 class="text-uppercase text-muted mt-1" v-if="title">{{title}}</h5>
        </div>
      </b-col>
    </b-row>
  </card>
</template>
<script>
  import Card from './Card.vue';

  export default {
    name: 'stats-card',
    components: {
      Card
    },
    props: {
      icon: String,
      title: String,
      subTitle: String,
      iconClasses: [String, Array]
    }
  };
</script>
